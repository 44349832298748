// @flow

import React, { Component } from 'react';
import type { PosteJoueurType, RencontreJoueursType, PosteStaffType, FaitDeJeuType } from 'types/Rencontre';
import { dynamicClassName } from 'utils/dynamicClassName';
import defaultImgPlayer from 'assets/img/placeholder-ffr.jpg';

const getLinesOfPlayer = props => {
  const { joueurs } = props;
  const firstLine = [joueurs.poste_1, joueurs.poste_2, joueurs.poste_3];
  const secondLine = [joueurs.poste_4, joueurs.poste_5];
  const thridLine = [joueurs.poste_6, joueurs.poste_8, joueurs.poste_7];
  const fourthLine = [joueurs.poste_9, joueurs.poste_10];
  const fifthLine = [joueurs.poste_11, joueurs.poste_12, joueurs.poste_13, joueurs.poste_14];
  const lastLine = [joueurs.poste_15];
  const remplacants = [
    joueurs.poste_16,
    joueurs.poste_17,
    joueurs.poste_18,
    joueurs.poste_19,
    joueurs.poste_20,
    joueurs.poste_21,
    joueurs.poste_22,
    joueurs.poste_23,
    joueurs.poste_24,
    joueurs.poste_25,
    joueurs.poste_26,
    joueurs.poste_27,
    joueurs.poste_28
  ];
  let remplacantsLine1 = [];
  let remplacantsLine2 = [];

  remplacants.forEach((remplacant, index) => {
    if (!remplacant) {
      return null;
    }
    if (remplacant.id !== 0 && index < 7) {
      remplacantsLine1.push(remplacant);
      return remplacantsLine1;
    } else if (remplacant.id !== 0 && index >= 7) {
      remplacantsLine2.push(remplacant);
      return remplacantsLine2;
    }
    return null;
  });

  return {
    firstLine,
    secondLine,
    thridLine,
    fourthLine,
    fifthLine,
    lastLine,
    remplacantsLine1,
    remplacantsLine2
  };
};

type Props = {
  isWomen: boolean,
  joueurs: RencontreJoueursType,
  staffs: PosteStaffType[],
  faits: FaitDeJeuType[]
};

type State = {
  aHovered: boolean,
  firstLine: PosteJoueurType[],
  secondLine: PosteJoueurType[],
  thridLine: PosteJoueurType[],
  fourthLine: PosteJoueurType[],
  fifthLine: PosteJoueurType[],
  lastLine: PosteJoueurType[],
  remplacantsLine1: PosteJoueurType[],
  remplacantsLine2: PosteJoueurType[],
  playerHovered: string,
  staffHovered: string,
  position: Object
};

export default class TeamComposition extends Component<Props, State> {
  state: State = {
    aHovered: false,
    firstLine: [],
    secondLine: [],
    thridLine: [],
    fourthLine: [],
    fifthLine: [],
    lastLine: [],
    remplacantsLine1: [],
    remplacantsLine2: [],
    playerHovered: '',
    staffHovered: '',
    position: {}
  };

  static getDerivedStateFromProps(props: Props) {
    if (props.joueurs) {
      return getLinesOfPlayer(props);
    }
    return null;
  }

  hoverOn = (e: SyntheticInputEvent<EventTarget>, ordre: number) => {
    const position = e.target.getBoundingClientRect();
    const playerHovered = ordre ? 'poste_' + ordre : e.target.id;
    const { bottom, left, right, top } = position;
    document.addEventListener('touchstart', this.hoverOut);
    if (playerHovered && playerHovered.match('^poste_')) {
      this.setState({ aHovered: true, playerHovered, staffHovered: '', position: { bottom, left, right, top } });
    }
  };

  hoverOnStaff = (e: SyntheticInputEvent<EventTarget>) => {
    const position = e.target.getBoundingClientRect();
    const staffHovered = e.target.id;
    const { bottom, left, right, top } = position;
    this.setState({ aHovered: true, staffHovered, playerHovered: '', position: { bottom, left, right, top } });
  };

  hoverOut = () => {
    document.removeEventListener('touchstart', this.hoverOut);
    this.setState({ aHovered: false });
  };

  renderLinesOfPlayer = (playerLine: PosteJoueurType[]): Array<React$Element<any> | null> | null => {
    const { faits } = this.props;
    if (playerLine.length > 0) {
      return playerLine.map(player => {
        if (!player || player.nom === '') {
          return null;
        }

        let estRemplace = false;
        let yellowCard = false;
        let redCard = false;
        const playerPictoClassNames = dynamicClassName('');
        if (player.est_remplace_minute > 0) {
          estRemplace = true;
          playerPictoClassNames.add('substitution');
        } else if (faits.length > 0) {
          const remplace = this.props.faits.find(fait => {
            return fait.quoi === 'remplacement' && fait.remplace === player.nom;
          });
          if (remplace) {
            estRemplace = true;
            playerPictoClassNames.add('substitution');
          }
        }
        if (player.cartons_jaunes > 0) {
          if (estRemplace) {
            yellowCard = true;
          } else {
            playerPictoClassNames.add('yellow-card');
          }
        } else if (faits.length > 0) {
          const cartonJaune = this.props.faits.find(fait => {
            return fait.quoi === 'carton_jaune' && fait.joueur_court === player.nom;
          });
          if (cartonJaune) {
            if (estRemplace) {
              yellowCard = true;
            } else {
              playerPictoClassNames.add('yellow-card');
            }
          }
        }

        if (player.cartons_rouges > 0) {
          if (estRemplace) {
            redCard = true;
          } else {
            playerPictoClassNames.add('red-card');
          }
        } else if (faits.length > 0) {
          const cartonRouge = this.props.faits.find(fait => {
            return fait.quoi === 'carton_rouge' && fait.joueur_court === player.nom;
          });
          if (cartonRouge) {
            if (estRemplace) {
              redCard = true;
            } else {
              playerPictoClassNames.add('red-card');
            }
          }
        }

        if (player.capitaine) {
          playerPictoClassNames.add('captain');
        }
        return (
          <div key={player.nom} className="schema__player">
            <a
              href={player.link}
              className={`poste_${player.ordre}`}
              onMouseEnter={e => this.hoverOn(e, player.ordre)}
              onMouseLeave={this.hoverOut}
            >
              <span>{player.ordre}</span>
              <p className={playerPictoClassNames.build()}>
                {player.nom}
                {yellowCard && <i className="yellow-card" />}
                {redCard && <i className="red-card" />}
              </p>
            </a>
          </div>
        );
      });
    }
    return null;
  };

  renderStaffsList = () => {
    const { staffs } = this.props;
    return staffs.map<any>(staff => {
      return (
        <li key={staff.id}>
          <p>
            <a
              href={staff.link}
              className={`staff_${staff.id}`}
              onMouseEnter={e => this.hoverOnStaff(e)}
              onMouseLeave={this.hoverOut}
            >
              <span id={`staff_${staff.id}`}>
                {this.roleToString(staff.role)} : {staff.nom}
              </span>
            </a>
          </p>
        </li>
      );
    });
  };

  renderPlayersList = (titulaires: PosteJoueurType[]): Array<React$Element<any> | null> => {
    const { joueurs, faits } = this.props;
    return titulaires.map(joueur => {
      if (!joueur) {
        return null;
      }

      const playerPictoClassNames = dynamicClassName('');
      const idPosteRemplacant = 'poste_' + joueur.est_remplace_ordre;
      let est_remplace = false;
      let yellowCard = false;
      let redCard = false;

      if (joueur.est_remplace_minute > 0) {
        playerPictoClassNames.add('substitution');
      } else if (faits.length > 0) {
        const remplace = this.props.faits.find(fait => {
          return fait.quoi === 'remplacement' && fait.remplace === joueur.nom;
        });
        if (remplace) {
          playerPictoClassNames.add('substitution');
          est_remplace = {
            nom: remplace.joueur_court,
            minute: remplace.quand
          };
        }
      }
      if (joueur.cartons_jaunes > 0) {
        if (est_remplace) {
          yellowCard = true;
        } else {
          playerPictoClassNames.add('yellow-card');
        }
      } else if (faits.length > 0) {
        const carton = this.props.faits.find(fait => {
          return fait.quoi === 'carton_jaune' && fait.joueur_court === joueur.nom;
        });
        if (carton) {
          if (est_remplace) {
            yellowCard = true;
          } else {
            playerPictoClassNames.add('yellow-card');
          }
        }
      }

      if (joueur.cartons_rouges > 0) {
        if (est_remplace) {
          redCard = true;
        } else {
          playerPictoClassNames.add('red-card');
        }
      } else if (faits.length > 0) {
        const carton = this.props.faits.find(fait => {
          return fait.quoi === 'carton_rouge' && fait.joueur_court === joueur.nom;
        });
        if (carton) {
          if (est_remplace) {
            redCard = true;
          } else {
            playerPictoClassNames.add('red-card');
          }
        }
      }

      if (joueur.capitaine) {
        playerPictoClassNames.add('captain');
      }

      if (joueur.nom === '') {
        return null;
      }
      return (
        <li key={joueur.ordre} data-num={joueur.ordre}>
          <p>
            <a
              href={joueur.link}
              className={`poste_${joueur.ordre}`}
              onMouseEnter={e => this.hoverOn(e, joueur.ordre)}
              onMouseLeave={this.hoverOut}
            >
              <span id={`poste_${joueur.ordre}`} className={playerPictoClassNames.build()}>
                {joueur.nom}
                {yellowCard && <span className="yellow-card"></span>}
                {redCard && <span className="red-card"></span>}
              </span>
            </a>
            {joueur.est_remplace_minute > 0 && (
              <span>{`${joueurs[idPosteRemplacant].nom} (${joueur.est_remplace_minute}')`}</span>
            )}
            {est_remplace && <span>{`${est_remplace.nom} (${est_remplace.minute}')`}</span>}
          </p>
        </li>
      );
    });
  };

  renderHoverCards = () => {
    const { joueurs } = this.props;
    const { position, aHovered, playerHovered } = this.state;
    if (position !== {} && playerHovered !== '') {
      const positionCard = {
        position: 'fixed',
        left: position.left - 100 < 0 ? 5 : position.left - 100,
        right: position.right - 100,
        top: position.top - 100,
        bottom: position.bottom - 100
      };
      const background =
        joueurs[playerHovered] && joueurs[playerHovered].featured_media
          ? joueurs[playerHovered].featured_media
          : defaultImgPlayer;
      return (
        <div
          key={`hoverCardPlayer${joueurs[playerHovered].ordre}`}
          className={aHovered ? 'composition__hoverCard active' : 'composition__hoverCard'}
          style={positionCard}
        >
          <div className="hoverCard__portrait" style={{ backgroundImage: `url(${background})` }}></div>
          <div className="hoverCard__desc">
            <h5 className="ft-h5">{joueurs[playerHovered].nom}</h5>
            <h6 className="ft-h6 ft-up">{joueurs[playerHovered].poste}</h6>
          </div>
        </div>
      );
    }
    return null;
  };

  roleToString = (role: string) => {
    switch (role) {
      case 'chef_delegation':
        return 'Chef de délégation';
      case 'manager':
        return 'Manager';
      case 'entraineur_01':
        return 'Entraineur';
      case 'entraineur_02':
        return 'Entraineur';
      case 'entraineur_03':
        return 'Entraineur';
      default:
        return role;
    }
  };
  renderStaffHoverCards = () => {
    const { staffs } = this.props;
    const { position, aHovered, staffHovered } = this.state;
    if (position !== {} && staffHovered !== '') {
      const positionCard = {
        position: 'fixed',
        left: position.left - 100,
        right: position.right - 100,
        top: position.top - 100,
        bottom: position.bottom - 100
      };
      const staffId = staffHovered.substr(6);
      let staff = staffs.filter(staff => staff.id === parseInt(staffId, 10));
      if (staff.length > 0) {
        staff = staff[0];
      } else {
        return null;
      }
      return (
        <div
          key={`hoverCardPlayer${staff.id}`}
          className={aHovered ? 'composition__hoverCard active' : 'composition__hoverCard'}
          style={positionCard}
        >
          <div className="hoverCard__portrait" style={{ backgroundImage: `url(${staff.featured_media})` }}></div>
          <div className="hoverCard__desc">
            <h5 className="ft-h5">{staff.nom}</h5>
            <h6 className="ft-h6 ft-up">{this.roleToString(staff.role)}</h6>
          </div>
        </div>
      );
    }
    return null;
  };

  render() {
    const {
      firstLine,
      secondLine,
      thridLine,
      fourthLine,
      fifthLine,
      lastLine,
      remplacantsLine1,
      remplacantsLine2
    } = this.state;
    const { staffs } = this.props;
    return (
      <div className="col col-lg-8on9">
        <h3 className="mb-3">{"Composition de l'équipe"}</h3>
        <div className="composition mt-4">
          <div className="row">
            <div className="composition__schema col col-lg-6">
              <div className="schema">
                <div className="schema__firstLine">{this.renderLinesOfPlayer(firstLine)}</div>
                <div className="schema__twoPlayer">{this.renderLinesOfPlayer(secondLine)}</div>
                <div className="schema__thirdLine">{this.renderLinesOfPlayer(thridLine)}</div>
                <div className="schema__twoPlayer schema__twoPlayer--second">
                  {this.renderLinesOfPlayer(fourthLine)}
                </div>
                <div className="schema__backLine">{this.renderLinesOfPlayer(fifthLine)}</div>
                <div className="schema__oneLine">{this.renderLinesOfPlayer(lastLine)}</div>
              </div>
            </div>
            <div className="composition__team col col-lg-6 mt-4 mt-lg-0">
              <div className="team">
                <div className="team__header">
                  <i className="icon icon-shirt" />
                  <span className="ft-h4">Titulaires</span>
                </div>
                <div className="team__body">
                  <ol className="mt-2">{this.renderPlayersList([...firstLine, ...secondLine, ...thridLine])}</ol>
                  <ol className="mt-0 mt-md-2">{this.renderPlayersList([...fourthLine, ...fifthLine, ...lastLine])}</ol>
                  {this.renderHoverCards()}
                </div>
                {(remplacantsLine1.length > 0 || remplacantsLine2.length > 0) && (
                  <div className="team__header mt-4">
                    <i className="icon icon-substitution" />
                    <span className="ft-h4">{`Remplaçant${this.props.isWomen ? 'e' : ''}s`}</span>
                  </div>
                )}
                {(remplacantsLine1.length > 0 || remplacantsLine2.length > 0) && (
                  <div className="team__body">
                    <ol className="mt-2">{this.renderPlayersList(remplacantsLine1)}</ol>
                    <ol className="mt-0 mt-md-2">{this.renderPlayersList(remplacantsLine2)}</ol>
                    {this.renderHoverCards()}
                  </div>
                )}
                {staffs && staffs.length > 0 && (
                  <div>
                    <div className="team__header mt-4">
                      <span className="ft-h4">Staff</span>
                    </div>
                    <div className="team__body">
                      <ul className="mt-2">{this.renderStaffsList()}</ul>
                      {this.renderStaffHoverCards()}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
