// @flow

import React, { Component } from 'react';
import { dynamicClassName } from 'utils/dynamicClassName';
import type { FaitDeJeuType } from "types/Rencontre";

type State = {
  isOpen: boolean,
};

type Props = {
  adversaire_stat: number,
  france_stat: number,
  faitType: string,
  faitsDeJeu: Array<FaitDeJeuType>,
  reception: boolean,
  afficher_faits: boolean
}

export default class StatsMatch extends Component<Props, State> {

  state: State = {
    isOpen : false,
  };

  handleClick = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  };

  renderScoreBodyInfos = (faits: Array<FaitDeJeuType>, equipe: string, type: string) => {
    const filteredFaits = faits.filter((fait) => fait.equipe === equipe && fait.quoi === type);
    if (equipe === 'france') {
      return filteredFaits.map<any>((fait) => (
          <p key={`${fait.quoi}__${fait.joueur}__${fait.quand}`}>
            {fait.quand !== '-1' &&
            <span>{`${fait.quand}'`}</span>}
            { fait.raison === 'De pénalité' && 
              fait.raison
            }
            {
              fait.joueur_court.split(' ').slice(1).join(' ') || fait.joueur.split(' ')[0].toLowerCase().charAt(0).toUpperCase() + 
              fait.joueur.split(' ')[0].toLowerCase().slice(1)
            }
          </p>
      ));
    } else if (equipe === 'adversaire') {
      return filteredFaits.map<any>((fait) => (
        <p key={`${fait.quoi}__${fait.joueur}__${fait.quand}`}>
          { fait.raison === 'De pénalité' && 
            <span>{fait.raison}{' '}</span>
          }

          { 
            fait.joueur.split(' ')[0].toLowerCase().charAt(0).toUpperCase() + 
            fait.joueur.split(' ')[0].toLowerCase().slice(1)
          }

          {fait.quand !== "-1" &&
          <span>{`${fait.quand}'`}</span>}
        </p>
      ));
    }
    return null;  
  }

  getProgressWidth = (adversaire_stat: number, france_stat: number) => {
    if (adversaire_stat === 0 && france_stat === 0) {
      return `0%`;
    }
    return `${france_stat * 100 / (adversaire_stat + france_stat)}%`;
  }

  render() {
    const { isOpen } = this.state;
    const { faitsDeJeu, adversaire_stat, france_stat, faitType, afficher_faits, reception } = this.props;
    let adversaire_stat_final = adversaire_stat;
    let france_stat_final = france_stat;
    if (adversaire_stat === 0 && faitsDeJeu.length > 0) {
      adversaire_stat_final = faitsDeJeu.filter((fait) => 
        fait.equipe === 'adversaire' && fait.quoi === faitType
      ).length;
    }
    if (france_stat === 0 && faitsDeJeu.length > 0) {
      france_stat_final = faitsDeJeu.filter((fait) => 
        fait.equipe === 'france' && fait.quoi === faitType
      ).length;
    }
    const classNames = dynamicClassName('');
    const scoreBodyClassNames = dynamicClassName('score__body');
    const scoreHeadIntClassNames = dynamicClassName('score__head__int');
    const scoreHeadExtClassNames = dynamicClassName('score__head__ext');

    const displayedTitle = faitType === 'carton_jaune' || faitType === 'carton_rouge' ? 
    `${faitType.split('_')[0]}s ${faitType.split('_')[1]}` : faitType;

    let progressClassNames = '';
    
    if (isOpen) {
      classNames.add('is-open');
    } else {
      classNames.add('is-close'); 
      scoreBodyClassNames.add('is-hidden');
    }

    if (!reception) {
      progressClassNames = 'is-right';
    }

    if ((reception && france_stat_final > adversaire_stat_final) || 
      (!reception && adversaire_stat_final > france_stat_final)) {
      scoreHeadIntClassNames.add('is-superior');
    } else if ((!reception && france_stat_final > adversaire_stat_final) || 
      (reception && adversaire_stat_final > france_stat_final)) {
      scoreHeadExtClassNames.add('is-superior');
    }

    return (
      <>
        <div className="score__head">
          {afficher_faits && 
          <a className={classNames.build().trim()} role="button" tabIndex={0} onClick={() => this.handleClick()}>
            {`${displayedTitle.substring(0, 1).toUpperCase()}${displayedTitle.slice(1)}s`}
          </a>}
          {!afficher_faits && 
          <a role="button" tabIndex={0} onClick={() => {return false;}}>
            {`${displayedTitle.substring(0, 1).toUpperCase()}${displayedTitle.slice(1)}s`}
          </a>}
          <span className={scoreHeadIntClassNames.build()}>
            {reception ? france_stat_final : adversaire_stat_final}
          </span>
          <div>
            <span 
              className={progressClassNames}
              style={{ width: this.getProgressWidth(adversaire_stat_final, france_stat_final) }}>
            </span>
          </div>
          <span className={scoreHeadExtClassNames.build()}>
            {reception ? adversaire_stat_final : france_stat_final}
          </span>
        </div>
        {afficher_faits && 
        <div className={scoreBodyClassNames.build()}>
          <div>
            {this.renderScoreBodyInfos(faitsDeJeu, reception ? 'france' : 'adversaire', faitType)}
          </div>
          <div>
            {this.renderScoreBodyInfos(faitsDeJeu, reception ? 'adversaire' : 'france', faitType)}
          </div>
        </div>}
      </>
    );
  }
}
